import { request } from "@/request/http";

/**
 * @name: 虚拟实验分类管理接口
 * @author: LXY
 * @date: 2022-10-20
 */
export default {
  // 获取授权虚拟实验列表
  getLockExpList: (data) => {
    return request("POST", `/index.php/adminUser/getLockExpList`, data);
  },
  updateUserExpLock: (data) => {
    return request("POST", `/index.php/adminUser/updateUserExpLock`, data);
  },
};