import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import { encryptCode,decryptCode } from "@/utils/function";
import {request_sign} from '@/utils/const'
import API from '@/api/manager/user/lockProject.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    // 页面名称
    name: "lockProject",
    data() {
        return {
            // 全选
            checkedAll:false,
            // 项目列表
            tableData: [],
            // 选择的项目
            multipleSelection: [],
            // 用户id
            user_id:'',
        }
    },
    /**
     * @name: 生命周期创建
     */
    created() 
    {
        let self = this;
        if(self.$route.query.params)
        {
            // console.log(decodeURIComponent(self.$route.query.params));
            // let params = decryptCode(this.$router.currentRoute.query.params);
            // console.log(params);
            // self.user_id = params?.id
            self.user_id =  sessionStorage.getItem('lockuserid')
            self.getData();
        }
    },
    methods:
    {   
        /**
         * @name: 获取数据
         */
        getData()
        {
            let self = this;
            let str = encryptCode({
                userid:self.user_id,
                app:'com.mools.chemlab'
            })
            let params = encryptCode({
                id:self.user_id,
            })
            let data = {sign:request_sign,params:str}
            nprogressStart();
            self.$Loading.show();
            self.$router.push('/manager/user/lockProject?params='+params);
            // 获取虚拟实验分类请求
            API.getLockExpList(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide();
                    if (result.code > 0) {
                        result.data?.forEach((item)=>{
                            item.explist?.forEach((key)=>{
                                key.lab_id = key.id;
                                key.lab_name = key.name;
                                // key.checked = false;
                                key.buystatus = key.buystatus == 0 ?false:true
                            })
                            let index = item.explist?.findIndex((value)=>{
                                return value.buystatus == 0
                            })
                            if(index >= 0)
                            {
                                item.buystatus = false
                            }
                            else if(item.explist.length == 0)
                            {
                                item.buystatus = false
                            }
                            else
                            {
                                item.buystatus = true
                            }
                        })
                        self.tableData = result.data
                        
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        /**
         * @name: 切换选中效果
         */
        toggleChecked(info)
        {
            if(!info.explist)
            {
                this.tableData.forEach((item)=>{
                    
                    let index = item.explist?.findIndex((key)=>{
                        return key.buystatus == 0
                    })
                    if(index >= 0 )
                    {
                        item.buystatus = false;
                    }
                    else if(item.explist.length == 0)
                    {
                        item.buystatus = false;
                    }
                    else
                    {
                        item.buystatus = true;
                    }
                    
                })
                let index = this.tableData.findIndex((item)=>{
                    return item.lab_id == exp.lab_id
                })
            }

            if(info.buystatus)
            {
                if(info?.explist?.length > 0){
                    info.explist.forEach((item)=>{
                        item.buystatus = true
                    })
                }
            }
            else
            {
                if(info?.explist?.length > 0){
                    info.explist.forEach((item)=>{
                        item.buystatus = false
                    })
                }
            }
            
        },
        /**
         * @name: 全选
         */
        toggleAll(buystatus)
        {
            this.tableData?.forEach((item)=>{
                item.buystatus = buystatus;
                item.explist?.forEach((key)=>{
                    key.buystatus = buystatus;
                })
            })
        },
        save()
        {
            let self = this;
            let explist = []
            this.tableData.forEach((item)=>{
                item.explist?.forEach((key)=>{
                    if(key.buystatus)
                    {
                        explist.push(key.id)
                    }
                })
            })
            let str = encryptCode({
                userid:  self.user_id,
                appName:'com.mools.chemlab',
                buyList : explist.toString()
            })
            // console.log({
            //     userid:  self.user_id,
            //     appName:'com.mools.chemlab',
            //     explist : explist.toString()
            // });
            let data = {sign:request_sign,params:str}
            nprogressStart();
            self.$Loading.show();
            // 获取虚拟实验分类请求
            API.updateUserExpLock(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide();
                    if (result.code > 0) {
                        // self.$router.push('/manager/user/lockProject?params=' +  str);
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
    }
};